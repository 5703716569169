import { Box, Button, Icon, Image } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useBlockTemplate } from "~/hooks/useBlockTemplate"
import { useCurrentAvailableProductsForBlock } from "~/hooks/useCurrentAvailableProductsForBlock"
import { useFilteredProductsForTemplate } from "~/hooks/useFilteredProductsForTemplate"
import { useProcessActions } from "~/hooks/useProcessActions"
import { useProductTemplate } from "~/hooks/useProductTemplate"
import { ReactComponent as TemplateThumbnailPlaceholderSvg } from "~/images/TemplateThumbnailPlaceholder.svg"
import { RootState } from "~/redux/store"
import { logger } from "~/services/Logger"

interface Props {
  blockId: EntityId
  productTemplateId: EntityId
  blockTemplateId: EntityId
  isCTAButtonEnabled: boolean
  ctaButtonText: string
}

const TemplateSelectButton: React.FC<Props> = ({
  blockId,
  productTemplateId,
  blockTemplateId,
  isCTAButtonEnabled,
  ctaButtonText,
}) => {
  const process = useProcessActions()

  const isLoadingBlockTemplates = useSelector(
    (state: RootState) =>
      state.blockTemplates.entities[blockTemplateId]?.isLoading
  )
  const { t } = useTranslation()
  const { productTemplate } = useProductTemplate(productTemplateId)
  const { blockTemplate } = useBlockTemplate(blockTemplateId)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const { products: potentialProducts } = useCurrentAvailableProductsForBlock(
    blockTemplate.blockId
  )

  const { products } = useFilteredProductsForTemplate(
    potentialProducts,
    productTemplateId
  )
  const { name, thumbnail } = productTemplate

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loggerData = {
    productTemplateName: name,
    productTemplateId,
  }

  const handleOnAddToCart = useCallback(() => {
    if (isLoadingBlockTemplates) {
      return
    }

    if (products.length > 0) {
      process.createLineItemFromTemplate({
        blockId,
        productId: products[0].id,
        productTemplateId: Number(productTemplateId),
        blockTemplateId,
      })
    } else {
      logger.debug(
        t("components.template.TemplateSelectButton.DebugLogger"),
        loggerData
      )
    }
  }, [
    isLoadingBlockTemplates,
    products,
    process,
    blockId,
    productTemplateId,
    blockTemplateId,
    loggerData,
    t,
  ])

  const blockWidth = 200 // Max width is 200px
  let blockHeight = 280
  // TODO this is a temporary fix, some template products does not have thumbnail attached
  // causing the page to crash
  if (thumbnail) {
    blockHeight = thumbnail.height / (thumbnail.width / blockWidth)
  }
  return (
    <Box
      id={`template--${productTemplateId}`}
      role="group"
      maxW={blockWidth}
      border="1px"
      borderColor="gray.100"
      boxShadow="md"
      transition="all 0.2s ease-in-out"
      opacity={isLoadingBlockTemplates ? 0.2 : 1}
      _hover={
        !isLoadingBlockTemplates
          ? {
              transform: "scale(1.05)",
              cursor: "pointer",
              boxShadow: "lg",
            }
          : {}
      }
      onClick={handleOnAddToCart}
    >
      <Box
        display={isImageLoaded ? "none" : "flex"}
        width="100%"
        height={{ sm: blockHeight }}
        alignItems="center"
        justifyContent="center"
      >
        <Icon as={TemplateThumbnailPlaceholderSvg} boxSize={16} />
      </Box>
      <Image
        display={isImageLoaded ? "block" : "none"}
        src={thumbnail?.url}
        alt={name}
        width="100%"
        height={{ sm: blockHeight }}
        onLoad={() => {
          setIsImageLoaded(true)
        }}
      />
      {Boolean(isCTAButtonEnabled) && (
        <Button
          id={`cta-button--${productTemplateId}`}
          colorScheme="primary"
          color="primary.text"
          className="cta-button"
          size="sm"
          left="50%"
          top="50%"
          transform="translate(-50%,-50%)"
          position="absolute"
          display="none"
          _groupHover={{ display: "inline-block" }}
        >
          {ctaButtonText ||
            t("components.template.TemplateSelectButton.CTAButtonDefault")}
        </Button>
      )}
    </Box>
  )
}

export default TemplateSelectButton
